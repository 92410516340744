import './App.css';
import 'dayjs/locale/ru';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { ConfigProvider } from 'antd';
import locale from 'antd/locale/ru_RU';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import { createRoot } from 'react-dom/client';
import { registerSW } from 'virtual:pwa-register';

import AppRoutes from './router';

library.add(fas);
dayjs.locale('ru');
dayjs.extend(isoWeek);

if ('serviceWorker' in navigator) {
    registerSW({
        onRegistered() {
            console.log('Service Worker активирован');
        },
        onRegisterError(error) {
            console.error('Service Worker ошибка:', error);
        },
    });
}

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <ConfigProvider locale={locale}>
        <AppRoutes />
    </ConfigProvider>
);
